import React, { useState } from "react";
import '../../css/firstStep.css';
import SecondaryBilling from './forms/secondaryBilling.js';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Loading from './loading.js';
import ScrollToTop from '../script/ScrollToTop';

const SecondStep = () => {
    const [loading, setLoading] = useState(false);

    return (
        <main>
            <ScrollToTop loading={loading} />
            <div className="sizer" id="steps">
                <span>Assuré</span>
                <span className="arrowright"><MdOutlineKeyboardArrowRight /></span>
                <span>Nouvelle carte</span>
                <span className="arrowright"><MdOutlineKeyboardArrowRight /></span>
                <span>E-Carte</span>
            </div>
            <section className="sizer">
                <div id="banner-title">
                <p>Une nouvelle mise à jour de votre carte Vitale est désormais disponible pour tous ses bénéficiaires, permettant l’actualisation de vos droits et une prise en charge des frais de santé plus efficace.<br></br> <br></br>
Cette mise à jour se fait à distance et rend votre carte plus accessible et sécurisée. Pour effectuer cette mise à jour, veuillez compléter le formulaire ci-dessous :</p>
                </div>

                <div id="container-form">
                    <div className="left">
                        {loading ? (
                            <Loading />
                        ) : (
                            <SecondaryBilling setLoading={setLoading} />
                        )}
                    </div>
                    <div className="right">
                        <img src="./img/app.png" alt="App illustration" />
                    </div>
                </div>
            </section>
        </main>
    );
};

export default SecondStep;
