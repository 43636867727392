import React from "react";

const Shipping = ({ onNext }) => {

    
    return(
        <>

        <div className="container-form-title"> 
            <h2>Frais administratif</h2>
            <p>Des frais de gestion de 1,20 € sont appliqués pour connecter informatiquement votre carte e-Vital.</p>
        </div>

        <img src="./img/frais.jpg" id="frais"></img>


      <button onClick={onNext}>Suivant</button>
      </>


    );
};

export default Shipping;