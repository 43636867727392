import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';


const FirstBilling = ({ setLoading }) => {

    const navigate = useNavigate(); // Initialize useNavigate

    const [formData, setFormData] = useState({
        name: '',
        dob: '',
        birthPlace: '',
        email: '',
        phone: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handlePhoneChange = (e) => {
        setFormData({
            ...formData,
            phone: e.target.value
        });
    };

    const handleFocus = (e) => {
        const { name } = e.target;
        setErrors({
            ...errors,
            [name]: ''
        });
    };


    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Nom et Prénom obligatoire';
        if (!formData.dob || formData.dob.length < 10) newErrors.dob = 'Date de naissance obligatoire';
        if (!formData.birthPlace || formData.birthPlace.length < 4) newErrors.birthPlace = 'Lieu de naissance obligatoire';
        if (!formData.email) newErrors.email = 'Adresse e-mail obligatoire';
        if (!formData.phone || formData.phone.length < 14) newErrors.phone = 'Numéro de téléphone obligatoire';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            sessionStorage.setItem('formData', JSON.stringify(formData));
            setTimeout(() => {
                setLoading(false);
                navigate('/billing');
            }, 2000); // 10 secondes de délai
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="container-form-title"> 
                    <h2>Mes informations</h2>
                    <p>Pour accéder à votre dossier, veuillez remplir les informations demandées ci-dessous.</p>
                </div>

                <div className="container-form">
                    <div className="container-input">
                        <label>Nom et Prénom</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onFocus={handleFocus}
                        />
                        {errors.name && <span className="error-span">{errors.name}</span>}
                    </div>

                    <div className="container-multiple-input">
                        <div className="container-input">
                            <label>Date de naissance</label>
                            <InputMask
                                mask="99/99/9999"
                                placeholder="JJ/MM/AAAA"
                                inputMode="numeric"
                                id="dob"
                                name="dob"
                                value={formData.dob}
                                onChange={handleChange}
                                onFocus={handleFocus}
                            >
                                {(inputProps) => <input {...inputProps} type="text" />}
                            </InputMask>
                            {errors.dob && <span className="error-span">{errors.dob}</span>}
                        </div>

                        <div className="container-input">
                            <label>Lieu de naissance</label>
                            <input
                                type="text"
                                name="birthPlace"
                                value={formData.birthPlace}
                                onChange={handleChange}
                                onFocus={handleFocus}
                            />
                            {errors.birthPlace && <span className="error-span">{errors.birthPlace}</span>}
                        </div>
                    </div>

                    <div className="container-input">
                        <label>Adresse e-mail</label>
                        <input
                            type="email"
                            name="email"
                            minLength={4}
                            maxLength={28}
                            value={formData.email}
                            onChange={handleChange}
                            onFocus={handleFocus}
                        />
                        {errors.email && <span className="error-span">{errors.email}</span>}
                    </div>

                    <div className="container-input">
                        <label>Numéro de téléphone</label>
                        <InputMask
                            mask="99 99 99 99 99"
                            maskChar={null}
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            onFocus={handleFocus}
                        >
                            {(inputProps) => <input {...inputProps} type="tel" />}
                        </InputMask>
                        {errors.phone && <span className="error-span">{errors.phone}</span>}
                    </div>

                    <div id='container-signature'>
                        <input type='checkbox' defaultChecked />
                        <span>Je certifie que les informations fournies ci-dessous me concernent et je suis conscient des conséquences en cas d'usurpation d'identité.</span>
                    </div>
                    <button type='submit'>Suivant</button>
                </div>
            </form>
        </>
    );
};

export default FirstBilling;
